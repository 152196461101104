<template>
  <v-app class>
    <div>
      <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Personnel Pending Approval</h1>
          </div>
          <v-row no-gutters>
            <v-col cols="12" class="">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-select v-model="action" class="pt-5" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0" label="Actions" return-object outlined dense />
                </v-col>
                <v-col cols="6 d-flex">
                  <div class="submit-button-container">
                    <v-btn small
                           class="btn btnSubmit"
                           :disabled="action === ''"
                           @click.prevent="dialogBulk = true"
                           :loading="selectLoading">Submit</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="col-md-12 ml-auto mr-auto">
            <v-text-field v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          hide-details></v-text-field>
          </div>
          <!-- <DataTable :headers="headers" :items="pendingEmployees" :approve="approve" :rejectFunc="reject" /> -->
          <!-- table  -->
           <div v-if="loadingData" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <v-data-table
            :headers='headers'
            :items="pendingApprovals"
            v-model="selected"
            :search="search"
            show-select
            :single-select="false">
            <template v-slot:item.statusDate="{ item }">
              {{item.statusDate | formatDate}}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="openConfiModal(item)" class="mr-2" />
                <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openRejectModal(item)" class="mr-2" />
                <Button :btnType="'Submit'" :color="'primary'" :label="'Return'" @onClick="openReturnModal(item)" />
              </div>
            </template>
          </v-data-table>

          <!-- reject dialog  -->
          <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField v-model="formData.comments" :label="'Comment'" />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button :label="'Reject'"
                        :btnType="'Submit'"
                        @onClick="reject"
                        :isLoading="rejectLoading"
                        :disabled="!valid"
                        class="mr-4" />
                <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- return dialog  -->
          <v-dialog v-model="returnDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for Returning</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField v-model="formData.comments" :label="'Comment'" />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button :label="'Return'"
                        :btnType="'Submit'"
                        @onClick="returnData"
                        :isLoading="returnLoading"
                        :disabled="!valid"
                        class="mr-4" />
                <v-btn color="green darken-1" text @click="returnDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve" />

          <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialogBulk" :btnTitle="'Yes'" @close="dialogBulk = false" @btnAction="approveInBulk" />

        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import TextField from '@/components/ui/form/TextField'
import { employeeAuditTrailService } from '@/services'
import {
  // FETCH_PENDING_EMPLOYEES,
  APPROVE_EMPLOYEE,
  RETURN_EMPLOYEE,
  REJECT_EMPLOYEE
} from '@/store/action-type'

export default {
  components: {
    Button,
    TextField,
    ConfirmationDialog
  },
  data () {
    return {
      search: '',
      alertMessage: '',
      returnDialog: false,
      returnLoading: false,
      rejectLoading: false,
      selected: [],
      pendingApprovals: [],
      selectLoading: false,
      action: '',
      alert: false,
      dialog: false,
      dialogBulk: false,
      item: null,
      isLoading: false,
      loadingData: true,
      alertType: '',
      valid: false,
      rejectDialog: false,
      formData: {
        comments: '',
        id: ''
      },
      headers: [
        {
          text: '',
          value: 'selected',
          sortable: false
        },        
        {
          text: 'Prnumber',
          value: 'prnumber'
        },
        {
          text: 'First Name',
          value: 'firstName'
        },
        {
          text: 'Last Name',
          value: 'lastName'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Location',
          value: 'location.name'
        },
        {
          text: 'Department',
          value: 'department.name'
        },
        {
          text: 'Audit Action',
          value: 'action'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }]
    }
  },
  computed: {
    // pendingEmployees () {
    //   return this.$store.getters.pendingEmployees
    // }

  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    pendingEmployees () {
      this.loadingData = true
      employeeAuditTrailService.getAllPendingEmployees().then(result => {
        this.pendingApprovals = result.data.items
      }).catch(() => {})
        .finally(() => {
          this.loadingData = false
        })
    },
    approve () {
      this.isLoading = true
      // const data = {
      //   status: 1,
      //   comments: '',
      //   id: this.item.id
      // }
      // console.log(data)
      this.item.status = 1
      this.$store
        .dispatch(APPROVE_EMPLOYEE, this.item)
        .then((result) => {
          this.showAlertMessage(
            'Approve request successful',
            'success'
          )
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Approval request failed' + error.Message, 'error')
        }).finally(() => {
          this.isLoading = false
          this.dialog = false
        })
    },

    approveInBulk () {
      this.isLoading = true
      console.log('item', this.selected)
      const data = []
      if (this.selected.length > 0) {
        this.selected.forEach(item => {
          data.push({
            Id: item.id,
            EmployeeId: item.employeeId,            
            FirstName: item.firstName,
            MiddleName: item.middleName,
            LastName: item.lastName,
            DateOfBirth: item.dateOfBirth,
            ResumptionDate: item.resumptionDate,
            RetirementDate: item.retirementDate,
            Email: item.email,
            AnnualSalaryAmount: item.annualSalaryAmount,
            Mobile: item.mobile,
            ContractEmployerId: item.contractEmployerId,
            ChargeCode: item.chargeCode,
            LocationId: item.locationId,
            DepartmentId: item.departmentId,
            JobTitleId: item.jobTitleId,
            RotationId: item.rotationId,
            WorkAreaId: item.workAreaId,
            approvers: item.approvers,
            prNumber: item.prnumber,
            UnionMemberId: item.unionMemberId,
            OrgId: item.orgId
          })
        })
      } else {
        data.push({
          id: this.item.id
        })
      }
      employeeAuditTrailService.approvePersonnel(data).then(() => {
        this.showAlertMessage(
          'New Personnel successfully ' + this.action.name + 'ed',
          'success'
        )
        this.pendingEmployees()
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Action failed', 'error')
      }).finally(() => {
        this.isLoading = false
        this.dialogBulk = false
        this.action = ''
      })
    },

    openRejectModal (item) {
      this.rejectDialog = true
      this.formData.id = item.id
    },
    openReturnModal (item) {
      this.returnDialog = true
      this.formData.id = item.id
    },
    reject () {
      this.rejectLoading = true
      const data = {
        status: 2,
        comments: this.formData.comments,
        id: this.formData.id
      }
      this.$store.dispatch(REJECT_EMPLOYEE, data).then((result) => {
        this.showAlertMessage(
          'Request successful',
          'success'
        )
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('Request failed', 'error')
      }).finally(() => {
        this.rejectDialog = false
        this.rejectLoading = false
        this.formData.comments = ''
        this.formData.id = ''
      })
    },
    returnData () {
      this.returnLoading = true
      const data = {
        status: 3,
        comments: this.formData.comments,
        id: this.formData.id
      }
      this.$store.dispatch(RETURN_EMPLOYEE, data).then((result) => {
        this.showAlertMessage(
          'Request successful',
          'success'
        )
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('Request failed', 'error')
      }).finally(() => {
        this.returnDialog = false
        this.returnLoading = false
        this.formData.comments = ''
        this.formData.id = ''
      })
    },
    openConfiModal (item) {
      this.item = item
      this.dialog = true
    },
    deleteItem (item) {
      console.log(item)
    }
  },
  mounted () {
    this.loadingData = true
    this.pendingEmployees()
    this.loadingData = false
    // this.$store
    //   .dispatch(FETCH_PENDING_EMPLOYEES)
    //   .then(() => {

    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     this.showAlertMessage('There are no items pending your approval ', 'warning')
    //   })
  }

}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }
  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .submit-button-container {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
  }

  .btn {
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "GothamBold",Arial,sans-serif;
    font-size: 14px;
    padding: 18.5px 20px !important;
    text-decoration: none;
    border-bottom-style: none;
    border-radius: 3;
    margin-left: 4px;
  }

  .btnSubmit {
    background: #0066b2 !important;
  }
</style>
